<template>
  <v-container class="d-flex justify-center">
    <v-row justify="center" class="flex-wrap">
      <v-col cols="12" sm="auto" class="d-flex justify-center mb-2">
        <v-btn elevation="16" :color="colors.secondary" @click="emitirEventoMostrarColaboradores"  outlined class="large-button rounded-button same-width">
          <v-icon left class="mr-2">mdi-account</v-icon>
          <span class="button-text">Colaboradores</span>
        </v-btn>
      </v-col>
      <!-- <v-col cols="12" sm="auto" class="d-flex justify-center mb-2">
        <v-btn elevation="16" :color="colors.secondary" outlined class="large-button rounded-button same-width">
          <v-icon left class="mr-2">mdi-office-building</v-icon>
       
          <span class="button-text md-only">Deptos</span>
        </v-btn>
      </v-col> -->
      <v-col cols="12" sm="auto" class="d-flex justify-center mb-2">
        <v-btn elevation="16" :color="colors.secondary" outlined class="large-button rounded-button same-width">
          <v-icon left class="mr-2">mdi-clipboard-check</v-icon>
          <span class="button-text">Tareas</span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="auto" class="d-flex justify-center mb-2">
        <v-btn elevation="16" :color="colors.secondary" @click="emitirEventoMostrarClientes" outlined class="large-button rounded-button same-width">
          <v-icon left class="mr-2">mdi-account-multiple</v-icon>
          <span class="button-text">Clientes</span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="auto" class="d-flex justify-center mb-2">
        <v-btn elevation="16" :color="colors.secondary" outlined class="large-button rounded-button same-width">
          <v-icon left class="mr-2">mdi-note</v-icon>
          <span class="button-text">Notas</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { colors } from '@/CSS/Design/Color';
export default {
  data() {
    return {
      name: 'ButtonGroup',

      colors
    };
  },
  methods: {
    emitirEventoMostrarClientes(){
      this.$emit('mostrar-clientes'); // Emitir evento hacia el componente padre
    },
    emitirEventoMostrarColaboradores() {
      this.$emit('mostrar-colaboradores'); // Emitir evento hacia el componente padre
    },
   
  },
};
</script>
  
<style scoped>
.large-button {
  font-size: 18px;
  padding: 12px 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-button {
  border-radius: 12px;
}

.mb-2 {
  margin-bottom: 16px;
}

.same-width {
  width: 250px;
  /* Ancho para pantallas grandes */
}

@media (max-width: 960px) {
  .same-width {
    width: 200px;
    /* Ancho para pantallas medianas */
  }

  .lg-only {
    display: none;
  }

  .md-only {
    display: inline;
  }
}

@media (min-width: 961px) {
  .md-only {
    display: none;
  }

  .lg-only {
    display: inline;
  }
}

.md-only {
  display: none;
}

.lg-only {
  display: inline;
}

.button-text {
  display: flex;
  align-items: center;
}</style>
  