<template>
    <v-card
    
    elevation="16"
      :loading="loading"
       class="mx-auto my-12 custom-border"
      max-width="374"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
  
      <v-img
        height="250"
        :src="require('../../assets/img/proyecto.png')"
      ></v-img>
  
      <v-card-title>Desarollo proyecto Bicodex</v-card-title>
  
      <v-card-text>
        <v-row
         
          class="mx-0"
        >
        
  
          <div class="grey--text">
           Desarrollar un sistema que permita la administracion de proyectos
          </div>
        </v-row>
  
        <div class="text-subtitle-2 mt-3">
          No. colaboradores: 2
        </div>
  
        <div class=" text-subtitle-2">
          No. tareas: 10
        </div>

        <div class="text-subtitle-2">
          No. tareas pendientes: 2
        </div>
        <!-- <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div> -->
      </v-card-text>
  
      <v-divider class="mx-4"></v-divider>
  
      <!-- <v-card-title>Tonight's availability</v-card-title>
   -->

   <v-progress-linear
      v-model="skill"
      :color="colors.secondary"
      
      height="25"
    >
      <template  v-slot:default="{ value }">
        <strong >{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
     
      <v-card-actions>
        <v-btn
          :color="colors.primary"
          text
          @click="reserve"
        >
          Ver tareas
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import { colors } from '../../CSS/Design/Color';
  export default {
    data: () => ({
      loading: false,
      selection: 1,
      skill: 80,
      knowledge: 33,
      power: 78,
      colors
    }),
  
    methods: {
      reserve () {
        this.loading = true
  
        setTimeout(() => (this.loading = false), 2000)
      },
    },
  }
  </script>
  
  <style scoped>
.custom-border {
  border: 0.5mm solid rgb(92, 92, 92); /* Puedes ajustar el grosor y color aquí */
  border-radius: 16px; /* Ajusta el radio según necesites */
}
</style>