<!-- src/views/NotFound.vue -->
<template>
    <div class="not-found">
      <h1>404 - Página No Encontrada</h1>
      <p>Lo sentimos, la página que estás buscando no existe.</p>
      <router-link to="/">Volver a la página principal</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound'
  }
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    margin-top: 50px;
  }
  h1 {
    font-size: 3em;
    color: #42b983;
  }
  p {
    margin: 20px 0;
    font-size: 1.2em;
  }
  a {
    color: #42b983;
    text-decoration: none;
    border: 2px solid #42b983;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  a:hover {
    background-color: #42b983;
    color: white;
  }
  </style>
  