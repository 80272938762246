<template>
  <v-alert
    v-if="showAlert"
    :type="alertType"
    dismissible
    @input="hideAlert"
    class="custom-alert"
  >
    <v-card-title>{{ alertTitle }}</v-card-title>
    <v-card-text>{{ alertMessage }}</v-card-text>
  </v-alert>
</template>

<script>
import { useAlertStore } from '@/stores/useAlertStore'; // Ajusta la ruta según la ubicación de tu store
import { computed, watch } from 'vue';

export default {
  name: 'AlertComponent',
  setup() {
    const alertStore = useAlertStore();
    const showAlert = computed(() => alertStore.showAlert);

    // Ocultar el alert automáticamente después de 5 segundos
    watch(showAlert, (newVal) => {
      if (newVal) {
        setTimeout(() => {
          alertStore.hide();
        }, 3000); // 5000 ms = 5 segundos
      }
    });

    return {
      showAlert,
      alertType: computed(() => alertStore.alertType),
      alertTitle: computed(() => alertStore.alertTitle),
      alertMessage: computed(() => alertStore.alertMessage),
      hideAlert: alertStore.hide,
    };
  },
};
</script>

<style scoped>
.custom-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30%; /* Ajusta el ancho según tus necesidades */
  z-index: 10000;
}
</style>