import axios from 'axios';
import Grupo from '../../models/Grupo/Grupo';
import API_URL from '@/config/api';

const cadena = localStorage.getItem('Cadena');
const cadenaEncoded = encodeURIComponent(cadena);

const apiClient = axios.create({
  baseURL: API_URL, // Cambia esto por tu URL base
  withCredentials: false, // Esto depende si tu API necesita credenciales o no
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  async getGrupos() {
    try {
      const url = `/Colaboradores/listaGrupos?idEmpresa=${localStorage.getItem('token')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.get(url);
      const grupos = response.data.map(grupo => new Grupo(
        grupo.id,
        grupo.Elemento,
        grupo.Estatus
      ));

      return grupos;
    } catch (error) {
      console.error('Error fetching grupos:', error);
      throw error;
    }
  },

  async getGrupo(id) {
    try {
      const response = await apiClient.get(`/grupos/${id}`);
      const grupo = response.data;
      return new Grupo(
        grupo.id,
        grupo.Elemento
      );
    } catch (error) {
      console.error(`Error fetching grupo with id ${id}:`, error);
      throw error;
    }
  },

  async createGrupo(grupo) {
    try {
      const url = `Colaboradores/nuevoGrupo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&Grupo=${grupo}`;
      const response = await apiClient.post(url);
      return response;
    } catch (error) {
      console.error('Error creating grupo:', error);
      throw error;
    }
  },

  async updateGrupo(id, grupo) {
    try {
      const url = `Colaboradores/editaGrupo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idGrupo=${id}&NuevoGrupo=${grupo}`;
      const response = await apiClient.put(url, grupo);
      return response
    } catch (error) {
      console.error(`Error updating grupo with id ${id}:`, error);
      throw error;
    }
  },

  async deleteGrupo(id) {
    try {
      const response = await apiClient.delete(`Colaboradores/eliminaGrupo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idGrupo=${id}`);
      return response
    } catch (error) {
      console.error(`Error deleting grupo with id ${id}:`, error);
      throw error;
    }
  }
};
