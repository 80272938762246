import axios from 'axios';
import Clientes from '../../models/clientes/clientes'; // Asegúrate de que esta ruta sea correcta
import API_URL from '@/config/api';
import TipoCliente from '@/models/clientes/TipoClientes';

const cadena = localStorage.getItem('Cadena');
const cadenaEncoded = encodeURIComponent(cadena);

const apiClient = axios.create({
  baseURL: API_URL, // Cambia esto por tu URL base
  withCredentials: false, // Esto depende si tu API necesita credenciales o no
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  async getClientes() {
    try {
      const url = `Clientes/listaClientes?idEmpresa=${localStorage.getItem('token')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.get(url);
      const clientes = response.data.map(cliente => new Clientes(
        cliente.idEmpresa,
        cliente.idCliente,
        cliente.Cliente,
        cliente.Telefono,
        cliente.Direccion,
        cliente.Email,
        cliente.RazonSocial,
        cliente.Rfc,
        cliente.Cp,
        cliente.Contacto,
        cliente.ContactoTelefono,
        cliente.idTipo,
        cliente.idGrupo,
        cliente.Estatus,
        cliente.Grupo,
        cliente.Tipo
      ));

      return clientes;
    } catch (error) {
      console.error('Error fetching clientes:', error);
      throw error;
    }
  },

  async getCliente(id) {
    try {
      const response = await apiClient.get(`/clientes/${id}`);
      const cliente = response.data;
      return new Clientes(
        cliente.idEmpresa,
        cliente.idCliente,
        cliente.Cliente,
        cliente.Telefono,
        cliente.Direccion,
        cliente.Email,
        cliente.RazonSocial,
        cliente.Rfc,
        cliente.Cp,
        cliente.Contacto,
        cliente.ContactoTelefono,
        cliente.idTipo,
        cliente.idGrupo,
        cliente.Estatus,
        cliente.Grupo,
        cliente.Tipo
      );
    } catch (error) {
      console.error(`Error fetching cliente with id ${id}:`, error);
      throw error;
    }
  },

  async getTipoClientes() {
    try {
      const url = `Clientes/listaTipos?idEmpresa=${localStorage.getItem('token')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.get(url);
      const tipoCliente = response.data.map(tipoCliente => new TipoCliente(
        tipoCliente.id,
        tipoCliente.Elemento,
      ));

      return tipoCliente;
    } catch (error) {
      console.error('Error fetching clientes:', error);
      throw error;
    }
  },

  async getGrupoClientes() {
    try {
      const url = `Clientes/listaGrupos?idEmpresa=${localStorage.getItem('token')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.get(url);
      const tipoCliente = response.data.map(tipoCliente => new TipoCliente(
        tipoCliente.id,
        tipoCliente.Elemento,
      ));

      return tipoCliente;
    } catch (error) {
      console.error('Error fetching clientes:', error);
      throw error;
    }
  },

  async createClienteGrupo(grupo) {
    try {
      const url = `Clientes/nuevoGrupo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&Grupo=${grupo}`;
      const response = await apiClient.post(url);
      return response;
    } catch (error) {
      console.error('Error creating grupo:', error);
      throw error;
    }
  },

  async updateClienteGrupo(id, grupo) {
    try {
      const url = `Clientes/editaGrupo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&Grupo=${grupo}&idGrupo=${id}`;
      console.log(url)
      const response = await apiClient.put(url, grupo);
      return response
    } catch (error) {
      console.error(`Error updating grupo with id ${id}:`, error);
      throw error;
    }
  },

  async deleteClienteGrupo(id) {
    try {
      const url = `Clientes/eliminaGrupo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idGrupo=${id}`;
      console.log(url)
      const response = await apiClient.delete(url);
      return response
    } catch (error) {
      console.error(`Error deleting grupo with id ${id}:`, error);
      throw error;
    }
  },


  async createCliente(cliente) {
    try {
      const url = `/Clientes/nuevoCliente?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.post(url, cliente);
      return response;
    } catch (error) {
      console.error('Error creating cliente:', error);
      throw error;
    }
  },

  async createTipoCliente(tipo) {
    try {
      const url = `/Clientes/nuevoTipo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&Tipo=${tipo}`;
      const response = await apiClient.post(url);
      return response;
    } catch (error) {
      console.error('Error creating cliente:', error);
      throw error;
    }
  },

  async updateTipoCliente(id, tipo) {  
    try {
      const url = `/Clientes/editaTipo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&Tipo=${tipo}&idTipo=${id}`;
      const response = await apiClient.put(url, tipo);
      return response;
    } catch (error) {
      console.error(`Error al actualizar cliente con el id ${id} mensaje:`, error);
      throw error;
    }
  },


  async updateCliente(id, cliente) {  
    try {
      const url = `/Clientes/actualizaCliente?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.put(url, cliente);
      return response;
    } catch (error) {
      console.error(`Error al actualizar cliente con el id ${id} mensaje:`, error);
      throw error;
    }
  },

  async deleteCliente(id) {
    try {
      
      const url = `/Clientes/eliminaCliente?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idCliente=${id}`;
      console.log(url);
      const response = await apiClient.delete(url);
      return response;
    } catch (error) {
      console.error(`Error al eliminar cliente con el id ${id} mensaje:`, error);
      throw error;
    }
  },

  async deleteTipoCliente(id) {
    try {
      
      const url = `/Clientes/eliminaTipo?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idTipo=${id}`;
      console.log(url);
      const response = await apiClient.delete(url);
      return response;
    } catch (error) {
      console.error(`Error al eliminar cliente con el id ${id} mensaje:`, error);
      throw error;
    }
  },

  formatDate(dateString) {
    if (!dateString) {
      return null;
    }

    const parts = dateString.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1;
      const year = parseInt(parts[2]);

      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        const date = new Date(year, month, day);
        const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
        return formattedDate;
      }
    }
    return null;
  }
};
