    //metodo para ingresar solo numeros
export const filterNumbers = (value) => {
    return value.replace(/[^\d]/g, '');
  };

   // Método para convertir texto a minusculas
  export const toLowerCase = (event, field) => {
    event.target.value = event.target.value.toLowerCase();
    this.editedItem[field] = event.target.value;
  };
  
  // Método para convertir texto a mayúsculas
  export const toUpperCase = (event, field) => {
    event.target.value = event.target.value.toUpperCase();
    this.editedItem[field] = event.target.value;
  };

  export const isValidEmail = (email) => {
    // Expresión regular para validar formato de correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };