export default class Clientes {
    constructor(
      idEmpresa,
      idCliente,
      Cliente,
      Telefono,
      Direccion,
      Email,
      RazonSocial,
      Rfc,
      Cp,
      Contacto,
      ContactoTelefono,
      idTipo,
      idGrupo,
      Estatus,
      Grupo,
      Tipo
    ) {
      this.idEmpresa = idEmpresa;
      this.idCliente = idCliente;
      this.Cliente = Cliente;
      this.Telefono = Telefono;
      this.Direccion = Direccion;
      this.Email = Email;
      this.RazonSocial = RazonSocial;
      this.Rfc = Rfc;
      this.Cp = Cp;
      this.Contacto = Contacto;
      this.ContactoTelefono = ContactoTelefono;
      this.idTipo = idTipo;
      this.idGrupo = idGrupo;
      this.Estatus = Estatus;
      this.Grupo = Grupo;
      this.Tipo = Tipo
    }
  }
  