<template>
    <v-container fluid>
        <v-card elevation="12" class="full-height-card">
            <v-data-table :headers="headers" :items="grupos" class="full-width-table">

              
                <template v-slot:top>
                    <v-toolbar flat :color="colors.secondary">
                        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>


                        <v-dialog v-model="dialog" max-width="1000px">

                            <template v-slot:activator="{ props }">
                                <v-btn class="mb-2 mr-5" dark variant="outlined" v-bind="props">
                                    Nuevo grupo
                                </v-btn>
                            </template>


                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" md="6" sm="12">
                                                <v-text-field v-model="editedItem.Elemento" :rules="[rules.required]"
                                                    @input="toUpperCase($event, 'Nombre')"
                                                    label="Nombre"></v-text-field>
                                            </v-col> 
                                           
                                            <v-col cols="12" md="6" sm="12">
                                                {{ editedItem.Estatus }}
                                              
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :color="colors.secondary" @click="close">Cancel</v-btn>
                                    <v-btn :color="colors.secondary" @click="save">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5 justify-center">¿Estás seguro de eliminar este
                                    registro?</v-card-title>

                                <v-card-actions class="justify-center">
                                    <v-btn color="error" variant="outlined" @click="closeDelete">Cancel</v-btn>
                                    <v-btn :color="colors.secondary" variant="outlined"
                                        @click="deleteItemConfirm">OK</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="me-2" size="small" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon size="small" @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">Reset</v-btn>
                </template>
            </v-data-table>
        </v-card>
        <AlertComponent />
    </v-container>
</template>

<script>
import { colors } from '../../CSS/Design/Color';
import AlertComponent from '../../components/Alerts/AlertComponent.vue';
import { useAlertStore } from '../../stores/useAlertStore';
import ClientesService from '@/services/clientes/ClientesService';

export default {
    components: {
        AlertComponent
    },
    data() {
        return {
            alertStore: useAlertStore(),
            rules: {
                required: v => !!v || 'Campo requerido',
                email: v => {
                    const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                    return pattern.test(v) || 'Email inválido';
                }
            },
            tituloModal: "Nuevo grupo",
            isDragging: false,
            file: null,
            dialog: false,
            dialogDelete: false,
            colaboradores: [],
            grupos: [],
            puestos: [],
            editedIndex: -1,
            editedItem: {
                Elemento: '',
                Estatus: '',
            },
            defaultItem: {
                Elemento: '',
                Estatus: '',

            },
            headers: [

                { title: 'Nombre', align: 'start', key: 'Elemento' },
              
                { title: 'Actions', key: 'actions', sortable: false },
            ],
            titulo: 'Grupos',
            colors,
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                thumbnailWidth: 100,
                maxFilesize: 0.5,
                headers: { 'My-Awesome-Header': 'header value' },
            },
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nuevo grupo' : 'Editar grupo';
        },
        switchModel: {
      get() {
        return this.editedItem.Estatus === 'true'; // Convierte la cadena a booleano
      },
      set(value) {
        this.editedItem.Estatus = value ? 'true' : 'false'; // Convierte booleano a cadena
      }
    }
    },
    methods: {
     
        onSubmit() {
            if (!this.form) return

            this.loading = true

            setTimeout(() => (this.loading = false), 2000)
        },
      
        toLowerCase(event, field) {
            this.editedItem[field] = event.target.value.toLowerCase();
        },
        toUpperCase(event, field) {
            this.editedItem[field] = event.target.value.toUpperCase();
        },

        handleFileInput(file) {
            if (file) {
                this.readFile(file);
            }
        },
        handleDrop(event) {
            this.isDragging = false;
            const files = event.dataTransfer.files;
            if (files.length > 0) {
                this.file = files[0];
                this.readFile(this.file);
            }
        },
        handleDragOver() {
            this.isDragging = true;
        },
        handleDragLeave() {
            this.isDragging = false;
        },
        readFile(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.editedItem.Imagen64 = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        handleFileInput_old() {
            const file = this.file;
            if (file && file instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.editedItem.Imagen64 = reader.result;
                };
                reader.readAsDataURL(file);
            } else {
                console.error('El archivo no es válido');
            }
        },
        async initialize() {
            try {
                const response = await ClientesService.getGrupoClientes();
                console.log(response)
                this.grupos = response;
            } catch (error) {
                console.error(error);
            }
        },
    
        editItem(item) {
          
            this.editedIndex = this.grupos.indexOf(item);
            this.editedItem = { ...item };
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.grupos.indexOf(item);
            this.editedItem = { ...item };
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            try {
                const response = await ClientesService.deleteClienteGrupo(this.editedItem.id);
                console.log(response)
                this.dialogDelete = false;
                console.log(response);
                if (response.data.Respuesta == "Ok") {
                    this.initialize();
                    this.alertStore.show('success', 'Grupo eliminado.', '');
                } else {
                    this.alertStore.show('error', 'No se pudo eliminar.', '');
                }
                // Actualiza la lista de colaboradores después de eliminar

            } catch (error) {
                console.error('Error eliminando colaborador:', error);
            }
        },
        close() {
            this.dialog = false;
            this.editedItem = { ...this.defaultItem };
            this.editedIndex = -1;
            this.file = null;
        },
        closeDelete() {
            this.dialogDelete = false;
            this.editedItem = { ...this.defaultItem };
            this.editedIndex = -1;
        },
        validateEditedItem() {
            if (!this.editedItem.Elemento) return false;
           

            // Agrega más validaciones según los campos que necesites
            return true;
        },
        async save() {
            try {
                if (!this.validateEditedItem()) {
                    this.alertStore.show('error', 'Todos los campos son obligatorios.', '');
                    return;
                }
                if (this.editedIndex > -1) {
                  
                  
                    console.log(this.editedItem)
                    const response = await ClientesService.updateClienteGrupo(this.editedItem.id, this.editedItem.Elemento);
                   
                    console.log(response)
                    if (response.data.Respuesta == "Ok") {
                        this.alertStore.show('success', 'Grupo actualizado.', '');
                    } else {
                        this.alertStore.show('error', 'No se pudo actualizar.', '');
                    }
                } else {
                    const response = await ClientesService.createClienteGrupo(this.editedItem.Elemento);
                  
                    if (response.data.Respuesta == "Ok") {
                        this.alertStore.show('success', 'Grupo agregado.', '');
                    } else {
                        this.alertStore.show('error', 'No se pudo crear.', '');
                    }

                }
                this.initialize();
                this.close();
            } catch (error) {
                console.error(error);
            }
        },
    },
    async mounted() {
        await this.initialize();

    },
};
</script>


<style scoped>
.full-height-card {
    height: 100%;
}

.full-width-table {
    width: 100%;
}

.dropzone {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
}

.dropzone.dragging {
    border-color: #42a5f5;
    background-color: #e3f2fd;
}

.dropzone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 600px) {

    .v-toolbar-title,
    .v-btn,
    .v-icon,
    .v-avatar,
    .dropzone-content p {
        font-size: 12px;
    }

    .v-avatar {
        width: 100px;
        height: 100px;
    }

    .dropzone {
        padding: 10px;
    }
}
</style>