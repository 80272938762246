<template>
  <v-container fluid>
    <v-card elevation="12" class="full-height-card">
      <v-data-table :headers="headers" :items="clientes" class="full-width-table">
        <template v-slot:top>
          <v-toolbar flat :color="colors.secondary">
            <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ props }">
                <v-btn class="mb-2 mr-5" dark variant="outlined" v-bind="props">
                  Nuevo cliente
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Cliente" :rules="[rules.required]"
                          @input="toUpperCase($event, 'Cliente')" label="Nombre del Cliente"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Telefono" @input="handlePhoneNumberInput"
                          :rules="[rules.required]" label="Teléfono"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Email" :rules="[rules.required, rules.email]"
                          @input="toLowerCase($event, 'Email')" label="Email" @blur="validateEmail"
                          hint="www.example.com"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Direccion" :rules="[rules.required]"
                          label="Dirección"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.RazonSocial" :rules="[rules.required]"
                          label="Razón Social"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Rfc" :rules="[rules.required]" label="RFC"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Cp" :rules="[rules.required]"
                          label="Código Postal"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Contacto" :rules="[rules.required]"
                          label="Contacto"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.ContactoTelefono" @input="handlePhoneNumberInput"
                          :rules="[rules.required]" label="Teléfono del Contacto"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select label="Tipo" :rules="[rules.required]" v-model="editedItem.idTipo" variant="outlined"
                          :items="tipoClientes" item-title="elemento" item-value="id"></v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select label="Grupo" :rules="[rules.required]" v-model="editedItem.idGrupo"
                          variant="outlined" :items="grupos" item-title="elemento" item-value="id"></v-select>
                          
                      </v-col>
                   
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :color="colors.secondary" @click="close">Cancel</v-btn>
                  <v-btn :color="colors.secondary" @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">¿Estás seguro de eliminar este registro?</v-card-title>
                <v-card-actions class="justify-center">
                  <v-btn color="error" variant="outlined" @click="closeDelete">Cancel</v-btn>
                  <v-btn :color="colors.secondary" variant="outlined" @click="deleteItemConfirm">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPuestoGrupo" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Agrega un nuevo {{ tituloNuevoPuestoGrupo }}</v-card-title>
                <v-text-field v-model="nombreGrupoPuesto" label="Nombre" :rules="[rules.required]"></v-text-field>
                <v-card-actions class="justify-center">
                  <v-btn color="error" variant="outlined" @click="dialogPuestoGrupo = false">Cancel</v-btn>
                  <v-btn :color="colors.secondary" variant="outlined" @click="CrearGrupoPuesto">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="me-2" size="small" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon size="small" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <AlertComponent />
  </v-container>
</template>


<script>
import { colors } from '../../CSS/Design/Color';
import ClienteService from '@/services/clientes/ClientesService';
import GrupoService from '@/services/Grupo/GrupoService';
import PuestosService from '@/services/Puesto/PuestoService';
import { isValidEmail, filterNumbers } from '@/utils/utils';
import AlertComponent from '../../components/Alerts/AlertComponent.vue';
import { useAlertStore } from '../../stores/useAlertStore';

export default {
  components: {
    AlertComponent
  },
  data() {
    return {
      alertStore: useAlertStore(),
      rules: {
        required: v => !!v || 'Campo requerido',
        email: v => {
          const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          return pattern.test(v) || 'Email inválido';
        }
      },
      tituloNuevoPuestoGrupo: "",
      nombreGrupoPuesto: "",
      dialogPuestoGrupo: false,
      tituloModal: "Nuevo cliente",
      isDragging: false,
      file: null,
      dialog: false,
      dialogDelete: false,
      clientes: [],
      tipoClientes: [],
      grupos: [],
      puestos: [],
      editedIndex: -1,
      editedItem: {
        idCliente: '',
        Cliente: '',
        Grupo: '',
        Telefono: '',
        Email: '',
        FechaNacimiento: '',
        Direccion: '',
        RazonSocial: '',
        Rfc: '',
        Cp: '',
        Contacto: '',
        ContactoTelefono: '',
        idGrupo: '',
        idPuesto: '',
        Estatus: '0',
      },
      defaultItem: {
        idCliente: '',
        Cliente: '',
        Grupo: '',
        Telefono: '',
        Email: '',
        FechaNacimiento: '',
        Direccion: '',
        RazonSocial: '',
        Rfc: '',
        Cp: '',
        Contacto: '',
        ContactoTelefono: '',
        idGrupo: '',
        idPuesto: '',
        Estatus: '0',
      },
      headers: [

        { title: 'Cliente', align: 'start', key: 'Cliente' },
        { title: 'Grupo', key: 'Grupo' },
        { title: 'Telefono', key: 'Telefono' },
        { title: 'Email', key: 'Email' },
        { title: 'Razon social', key: 'RazonSocial' },
        { title: 'RFC', key: 'Rfc' },
        { title: 'Contacto', key: 'Contacto' },
        { title: 'Tel. Contacto', key: 'ContactoTelefono' },
        { title: 'Actions', key: 'actions', sortable: false },
      ],
      titulo: 'Administración de clientes',
      colors,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 100,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo cliente' : 'Editar cliente';
    },
  },
  methods: {
    async getTipoClientes() {
      try {
        const response = await ClienteService.getTipoClientes()
        console.log(response)
        this.tipoClientes = response.map(item => ({
          elemento: item.Elemento,
          id: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    handlePhoneNumberInput(event) {
      this.editedItem.Telefono = filterNumbers(event.target.value);
    },
    onSubmit() {
      if (!this.form) return

      this.loading = true

      setTimeout(() => (this.loading = false), 2000)
    },
    abrirModalNuevoPuestoGrupo(cual) {
      if (cual === 1) {//El numero 1 es el Grupo
        this.tituloNuevoPuestoGrupo = "Grupo";

      } else { //El numero 2 es el Puesto
        this.tituloNuevoPuestoGrupo = "Puesto";

      }
      this.dialogPuestoGrupo = true;
    },
    validateEmail() {
      if (!isValidEmail(this.editedItem.Email)) {
        // Aquí puedes manejar el caso de correo electrónico no válido,
        // por ejemplo, mostrar un mensaje de error o realizar alguna acción.
        console.log('Correo electrónico no válido');
      } else {
        console.log('Correo electrónico válido');
      }
    },
    toLowerCase(event, field) {
      this.editedItem[field] = event.target.value.toLowerCase();
    },
    toUpperCase(event, field) {
      this.editedItem[field] = event.target.value.toUpperCase();
    },

    handleFileInput(file) {
      if (file) {
        this.readFile(file);
      }
    },
    handleDrop(event) {
      this.isDragging = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0];
        this.readFile(this.file);
      }
    },
    handleDragOver() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    readFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.editedItem.Imagen64 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleFileInput_old() {
      const file = this.file;
      if (file && file instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          this.editedItem.Imagen64 = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        console.error('El archivo no es válido');
      }
    },
    async initialize() {
      try {
        const response = await ClienteService.getClientes();

        this.clientes = response;
        console.log(this.clientes);
      } catch (error) {
        console.error(error);
      }
    },
    async getGrupos() {
      try {
        const response = await ClienteService.getGrupoClientes();
        this.grupos = response.map(item => ({
          elemento: item.Elemento,
          id: item.id,
        }));
        console.log(this.grupos);
      } catch (error) {
        console.error(error);
      }
    },
    async getPuestos() {
      try {
        const response = await PuestosService.getPuestos();
        this.puestos = response.map(item => ({
          elemento: item.Elemento,
          id: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    editItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        const response = await ClienteService.deleteCliente(this.editedItem.idCliente);
        this.dialogDelete = false;
        console.log(response);
        if (response.data.Respuesta == "Ok") {
          this.initialize();
          this.alertStore.show('success', 'Cliente eliminado.', '');
        } else {
          this.alertStore.show('error', 'No se pudo eliminar.', '');
        }
        // Actualiza la lista de clientes después de eliminar

      } catch (error) {
        console.error('Error eliminando cliente:', error);
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
      this.file = null;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    },
    validateEditedItem() {
      console.log(this.editedItem)
      if (!this.editedItem.Cliente) return false;
      if (!this.editedItem.Telefono) return false;
      if (!this.editedItem.Email) return false;
      if (!this.editedItem.Direccion) return false;
      if (!this.editedItem.RazonSocial) return false;
      if (!this.editedItem.Rfc) return false;
      if (!this.editedItem.Cp) return false;
      if (!this.editedItem.Contacto) return false;
      if (!this.editedItem.ContactoTelefono) return false;


      // Agrega más validaciones según los campos que necesites
      return true;
    },
    async save() {
      try {
        if (!this.validateEditedItem()) {
          this.alertStore.show('error', 'Todos los campos son obligatorios.', '');
          return;
        }
        if (this.editedIndex > -1) {
          console.log(this.editedItem)
          const response = await ClienteService.updateCliente(this.editedItem.idCliente, this.editedItem);

          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Cliente actualizado.', '');
          } else {
            this.alertStore.show('error', 'No se pudo actualizar.', '');
          }
        } else {
          const response = await ClienteService.createCliente(this.editedItem);

          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Cliente agregado.', '');
          } else {
            this.alertStore.show('error', 'No se pudo crear.', '');
          }
        }
        this.initialize();
        this.close();
      } catch (error) {
        console.error(error);
      }
    },
    async CrearGrupoPuesto() {
      try {

        if (this.tituloNuevoPuestoGrupo == "Grupo") {

          const response = await GrupoService.createGrupo(this.nombreGrupoPuesto);
          console.log(response)
          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Grupo agregado.', '');
            this.getGrupos();
          } else {
            this.alertStore.show('error', 'No se pudo crear.', '');
          }

        } else {
          const response = await PuestosService.createPuesto(this.nombreGrupoPuesto)
          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Puesto agregado.', '');
            this.getPuestos();
          } else {
            this.alertStore.show('error', 'No se pudo crear.', '');
          }

        }
        this.dialogPuestoGrupo = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {

    await this.initialize();
    await this.getGrupos();
    await this.getPuestos();
    await this.getTipoClientes();
  },

};
</script>


<style scoped>
.select-with-button {
  display: flex;
  align-items: center;
}

.select-button {
  margin-left: 2px;
  /* Ajusta esto según sea necesario */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 56px;
  /* Ajusta esto para que coincida con la altura del select */
  min-width: 56px;
  /* Ajusta esto para que coincida con la altura del select */
}

.select-button-Grupos {
  margin-left: 2px;
  /* Ajusta esto según sea necesario */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-bottom: 20px;
  height: 56px;

  /* Ajusta esto para que coincida con la altura del select */
  min-width: 56px;
  /* Ajusta esto para que coincida con la altura del select */
}

.full-height-card {
  height: 100%;
}

.full-width-table {
  width: 100%;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
}

.dropzone.dragging {
  border-color: #42a5f5;
  background-color: #e3f2fd;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {

  .v-toolbar-title,
  .v-btn,
  .v-icon,
  .v-avatar,
  .dropzone-content p {
    font-size: 12px;
  }

  .v-avatar {
    width: 100px;
    height: 100px;
  }

  .dropzone {
    padding: 10px;
  }
}
</style>