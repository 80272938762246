// models/Colaborador.js
export default class Colaborador {
    constructor(
   
      idColaborador,
      Nombre,
      Telefono,
      Email,
      FechaNacimiento,
      idGrupo,
      Grupo,
      idPuesto,
      Puesto,
      idPerfil,
      Perfil,
      EsUsuario,
      Imagen64,
      UltimoAcceso,
      
    ) {
    
      this.idColaborador = idColaborador;
      this.Nombre = Nombre;
      this.Telefono = Telefono;
      this.Email = Email;
      this.FechaNacimiento = FechaNacimiento;
      this.idGrupo = idGrupo;
      this.Grupo = Grupo;
      this.idPuesto = idPuesto;
      this.Puesto = Puesto;
      this.idPerfil = idPerfil;
      this.Perfil = Perfil;
      this.EsUsuario = EsUsuario;
      this.Imagen64 = Imagen64;
      this.UltimoAcceso = UltimoAcceso;
    }
  }
  