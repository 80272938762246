import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alert', {
  state: () => ({
    showAlert: false,
    alertType: 'info', // Puede ser 'success', 'error', 'warning', 'info'
    alertTitle: '',
    alertMessage: '',
  }),
  actions: {
    show(type, title, message) {
      this.alertType = type;
      this.alertTitle = title;
      this.alertMessage = message;
      this.showAlert = true;
    },
    hide() {
      this.showAlert = false;
    },
  },
});