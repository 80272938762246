// models/Colaborador.js
export default class Grupo {
    constructor(
      id,
      Elemento,
      Estatus
      
    ) {
    
      this.id = id;
      this.Elemento = Elemento;
      this.Estatus = Estatus;
     
    }
  }
  