import axios from 'axios';
import Grupo from '../../models/Puesto/Puesto';
import API_URL from '@/config/api';

const cadena = localStorage.getItem('Cadena');
const cadenaEncoded = encodeURIComponent(cadena);

const apiClient = axios.create({
  baseURL: API_URL, // Cambia esto por tu URL base
  withCredentials: false, // Esto depende si tu API necesita credenciales o no
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  async getPuestos() {
    try {
      const url = `/Colaboradores/listaPuestos?idEmpresa=${localStorage.getItem('token')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.get(url);
      const puestos = response.data.map(puesto => new Grupo(
        puesto.id,
        puesto.Elemento
      ));

      return puestos;
    } catch (error) {
      console.error('Error fetching puestos:', error);
      throw error;
    }
  },

  async getPuesto(id) {
    try {
      const response = await apiClient.get(`/puestos/${id}`);
      const puesto = response.data;
      return new Grupo(
        puesto.id,
        puesto.Elemento
      );
    } catch (error) {
      console.error(`Error fetching puesto with id ${id}:`, error);
      throw error;
    }
  },

  async createPuesto(puesto) {
    try {
      const url = `Colaboradores/nuevoPuesto?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&Puesto=${puesto}`;
      console.log(url);
      const response = await apiClient.post(url, puesto);
      return response;
    } catch (error) {
      console.error('Error creating puesto:', error);
      throw error;
    }
  },

  async updatePuesto(id, puesto) {
    try {
      const url = `Colaboradores/editaPuesto?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idPuesto=${id}&NuevoPuesto=${puesto}`;
      console.log(url);
      const response = await apiClient.put(url, puesto);
      return response;
    } catch (error) {
      console.error(`Error updating puesto with id ${id}:`, error);
      throw error;
    }
  },

  async deletePuesto(id) {
    try {
     const response = await apiClient.delete(`Colaboradores/eliminaPuesto?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idPuesto=${id}`);
     return response;
    } catch (error) {
      console.error(`Error deleting puesto with id ${id}:`, error);
      throw error;
    }
  }
};
