<template>
  <v-app>
    <!-- <v-app-bar app color="primary">
      <v-spacer></v-spacer>
      <v-btn @click="logout">Logout</v-btn>
    </v-app-bar> -->

    <Toolbar @navigate="manejarNavigate"></Toolbar>
    <v-main>
      <v-container>
        <BotonesOpciones @mostrar-colaboradores="mostrarColaboradores" @mostrar-clientes="mostrarClientes"></BotonesOpciones>
       
        <v-row>
      <v-col cols="12" sm="4" v-for="n in 6" :key="n">
        <cardProyectos />
      </v-col>
    </v-row>

          <router-view />
      
      </v-container>
    </v-main>
    <AlertComponent />
  </v-app>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAlertStore } from '../stores/useAlertStore'; // Asegúrate de ajustar la ruta según la ubicación de tu store
import Toolbar from '../components/Toolbar/ToolbarView.vue'; // Importa tu componente Toolbar
import BotonesOpciones from '../components/BotonesDashBoard/BotonesOpciones';
import AlertComponent from '../components/Alerts/AlertComponent.vue';
import cardProyectos from '../components/Proyectos/card-proyecto-component.vue';

export default {
  components: {
    Toolbar,
    BotonesOpciones,
    AlertComponent,
    cardProyectos
  },
  setup() {
    const router = useRouter();
    const alertStore = useAlertStore();
    const message = ref('Hello, Vue!');

    onMounted(() => {
      alertStore.show('success', 'Bienvenido.', '');
      // Aquí puedes ejecutar código que depende del DOM
    });

    const logout = () => {
      localStorage.removeItem('token');
      router.push({ name: 'Login' });
    };

    const mostrarColaboradores = () => {
      router.push("/Inicio/colaboradores").catch((failure) => {
        console.log(failure);
      });
    };

    const mostrarClientes = () => {
      router.push("/Inicio/clientes").catch((failure) => {
        console.log(failure);
      });
    };

    const mostrarGrupos = () => {
      router.push("/Inicio/Grupos").catch((failure) => {
        console.log(failure);
      });
    };

    const mostrarPuestos = () => {
      router.push("/Inicio/Puestos").catch((failure) => {
        console.log(failure);
      });
    };

    const mostrarTipoClientes = () => {
      router.push("/Inicio/TipoClientes").catch((failure) => {
        console.log(failure);
      });
    };

    const mostrarGrupoClientes = () => {
      router.push("/Inicio/GrupoClientes").catch((failure) => {
        console.log(failure);
      });
    };

    const manejarNavigate = (opcion) => {
      if (opcion === 'grupos') {
        mostrarGrupos();
      } else if (opcion === 'puestos') {
        mostrarPuestos();
      }else if (opcion === 'tipoCliente'){
        mostrarTipoClientes();
      }else if (opcion === 'grupoCliente'){
        mostrarGrupoClientes();
      }
    };

    return {
      message,
      logout,
      mostrarColaboradores,
      mostrarGrupos,
      manejarNavigate,
      mostrarClientes
    };
  }
};
</script>
