<template>
  <v-app-bar app :color="colors.primary">
    <v-toolbar-title>

      <img :src="require('../../assets/logo_blanco.png')" style="max-width:150px; max-height: 150px;" />
         
    </v-toolbar-title>

    <!-- Botones adicionales -->
    <v-btn text icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn>

    <v-btn text icon>
      <v-icon>mdi-email</v-icon>
    </v-btn>


    <PerfilOptions  @navigate="handleNavigate"></PerfilOptions>
  </v-app-bar>
</template>

<script>
import { useRouter } from 'vue-router';
import { colors } from '@/CSS/Design/Color';
import PerfilOptions from '../PopoverMenu/PerfilOptions'
export default {
  components: {

    PerfilOptions
},
  data() {
    return {
      menuItems: [
        { text: 'Settings', action: 'settings' },
        { text: 'Help', action: 'help' },
        { text: 'Logout', action: 'logout' }
      ],
      colors
    };

  },
  methods: {
    toggleDrawer() {
      // Implementa lógica para alternar el cajón aquí
      this.drawerOpen = !this.drawerOpen; // Suponiendo que `drawerOpen` es una variable de datos

      // Puedes emitir eventos, hacer cambios en el estado, o ejecutar otras lógicas según sea necesario
      console.log('Toggled Drawer:', this.drawerOpen);
    },
    handleMenuItemClick(item) {
      // Implementa manejo de clic en el elemento del menú basado en item.action
      console.log('Clic en:', item.text);
    },
    mostrarColaboradores() {
      useRouter.push("/Inicio/Grupos").catch((failure) => {
        console.log(failure);
      })
    },
    handleNavigate(opcion) {
      this.$emit('navigate',opcion); // Emite un evento 'navigate' al abuelo
    },
    
  }
};
</script>

<style>
/* Añade estilos personalizados aquí si es necesario */
</style>
