export default class Puesto {
    constructor(
      id,
      Elemento,
      
    ) {
    
      this.id = id;
      this.Elemento = Elemento;
     
    }
  }