<template>
  <div class="text-center">
    <v-menu v-model="menu" :close-on-content-click="false" location="end">
      <template v-slot:activator="{ props }">
        <v-btn text icon v-bind="props">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-card min-width="300">

        <v-list>
          <v-list-item prepend-avatar="https://cdn.vuetifyjs.com/images/john.jpg" subtitle="Programador"
            title="Omar campos">
            <!-- <template v-slot:append>
                            <v-btn :class="fav ? 'text-red' : ''" icon="mdi-heart" variant="text"
                                @click="fav = !fav"></v-btn>
                        </template> -->
          </v-list-item>
        </v-list>


        <v-list-item>
          <!-- <v-switch v-model="hints" color="purple" label="Enable hints" hide-details></v-switch>-->
          <v-list-item>
            <v-menu v-model="menuCatalogo" :close-on-content-click="false" activator="parent" offset-y>
              <template #activator="{ on, attrs }">
                <v-btn variant="text" :color="colors.secondary" dark v-bind="attrs" v-on="on">
                  Catálogos
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, index) in items" :key="index" @click="selectItem(item)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list-item>
        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-btn variant="text" @click="logout">Logout</v-btn>
          </v-list-item>


        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { colors } from '../../CSS/Design/Color';


export default {
  data: () => ({
    colors,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    menuCatalogo: false,
    items: [
      { title: 'Grupos', action: 'grupos' },
      { title: 'Puestos', action: 'puestos' },
      { title: 'Perfiles', action: 'perfiles' },
      { title: 'Grupos clientes', action: 'grupoCliente' },
      { title: 'Tipos clientes', action: 'tipoCliente' }
    ]
  }), methods: {
    logout() {
      localStorage.removeItem('token');

      this.$router.push({ name: 'Login' });
    },
    selectItem(item) {
      console.log(item.action);
      this.$emit('navigate', item.action);
      this.menuCatalogo = false; // Cierra el menú después de seleccionar
    },
    callGrandparentFunction(opcion) {
     console.log(opcion)
      this.$emit('navigate', opcion);
    },
    emitirEventoMostrarGrupos() {
      this.$emit('mostrar-grupos'); // Emitir evento hacia el componente padre
    },
  }
}
</script>

<style>
.rounded-button {
  border-radius: 12px;
}
</style>