<template>
  <v-container fluid class="d-flex fill-height main-background">
    <!-- Lado izquierdo con imagen (oculto en dispositivos pequeños) -->
    <!-- <v-col cols="12" md="8" class="left-side d-none d-md-flex flex-column justify-center align-center">
      <v-img v-if="isLightTheme" class="mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
        src="/assets/media/auth/agency.png" alt="Light Theme Image" />
      <v-img v-else class="mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" src="/assets/media/auth/agency-dark.png"
        alt="Dark Theme Image" /> -->
    <!-- <h1 class="text-gray-800 fs-2qx fw-bold text-center mb-7"> <v-icon color="#FF8E2B">mdi-format-list-checks</v-icon>CheckList</h1> -->
    <!-- <div class="text-gray-600 fs-base text-center fw-semibold"> -->
    <!-- Contenido adicional -->
    <!-- </div>
    </v-col> -->

    <!-- Lado derecho con formulario -->
    <v-col cols="12" class="d-flex justify-center align-center">
      <v-sheet elevation="16" class="right-side d-flex flex-column justify-center align-center p-4 form-container">
        <v-form @submit.prevent="login" class="w-100">
          <div class="text-center mb-11">
            <img :src="require('../../assets/logo_negro.png')" style="max-width:300px; max-height: 300px;" />
          </div>

          <v-text-field v-model="email" label="usuario@servidor.com" autocomplete="off" prepend-icon="mdi-account"
            class="mb-8" outlined required />
          <v-text-field v-model="password" label="Contraseña" autocomplete="off" prepend-icon="mdi-lock" class="mb-3"
            outlined type="password" required />
          <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
            <div></div>
            <a href="/Login/Olvido" class="link-primary">¿Olvidó su clave?</a>
          </div>
          <v-btn block type="submit" :color="colors.primary" class="mb-10" :loading="loading">
            <span class="indicator-label">Ingresar</span>
            <span v-if="loading" class="indicator-progress">
              Espere por favor...
              <v-progress-circular indeterminate size="20"></v-progress-circular>
            </span>
          </v-btn>
          <div class="text-gray-500 text-center fw-semibold fs-6">
            ¿No tiene cuenta?
            <a href="/Login/Registro" class="link-primary">Registrarse</a>
          </div>
        </v-form>
        <div class="d-flex flex-stack">
          <div class="d-flex fw-semibold text-primary fs-base gap-5">
            <a href="pages/contact.html" target="_blank">Contáctenos</a>
          </div>
        </div>
      </v-sheet>
    </v-col>
    <AlertComponent />
  </v-container>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAlertStore } from '../../stores/useAlertStore';
import AlertComponent from '../../components/Alerts/AlertComponent.vue';
import { colors } from '@/CSS/Design/Color';
import API_URL from '@/config/api';


export default {
  components: {
    AlertComponent,
  },
  setup() {
    const email = ref('');
    const password = ref('');
    const loading = ref(false);
    const router = useRouter();
    const alertStore = useAlertStore();

    const login = async () => {
      loading.value = true; // Activa el indicador de progreso al iniciar sesión

      try {
        const response = await axios.get(`${API_URL}/Login/Ingresar?Usuario=${email.value}&Password=${password.value}`);

        if (response.data.Estatus === "Exito") {
          console.log(response.data)
          localStorage.setItem('token', response.data.idEmpresa);
          localStorage.setItem('Cadena', response.data.Cadena);
          localStorage.setItem('idUsuario', response.data.idUsuario);
          router.push({ name: 'Dashboard' });
        } else {
          alertStore.show('error', 'Error', `${response.data.Estatus}`);
        }
      } catch (error) {
        alertStore.show('error', 'Error en la petición', error.message);
      } finally {
        loading.value = false; // Detener el estado de carga
      }
    };

    return {
      email,
      password,
      loading,
      login,
      colors
    };
  },
};
</script>

<style scoped>
.fill-height {
  height: 100vh;
  display: flex;
}

.main-background {
  background-image: url('../../assets/img/fondo.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
  /* Color de fondo de respaldo */
}

.left-side {
  background-image: url('../../assets/img/fondo.jpeg');
  background-size: cover;
  background-position: center;
  height: 100%;
}

.right-side {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.form-container {
  width: 100%;
}

@media (min-width: 600px) {
  .form-container {
    width: 85%;
  }
}

@media (min-width: 960px) {
  .form-container {
    width: 70%;
  }
}

@media (min-width: 1264px) {
  .form-container {
    width: 40%;
  }
}
</style>
