import axios from 'axios';
import Colaborador from '../../models/Colaborador/Colaborador';
import API_URL from '@/config/api';

const cadena = localStorage.getItem('Cadena');
const cadenaEncoded = encodeURIComponent(cadena);


const apiClient = axios.create({
  baseURL: API_URL, // Cambia esto por tu URL base
  withCredentials: false, // Esto depende si tu API necesita credenciales o no
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  async getColaboradores() {
    try {

      const url =  `/Colaboradores/listaColaboradores?idEmpresa=${localStorage.getItem('token')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.get(url);
      const colaboradores = response.data.map(colaborador => new Colaborador(
        colaborador.idColaborador,
        colaborador.Nombre,
        colaborador.Telefono,
        colaborador.Email,
        this.formatDate(colaborador.FechaNacimiento),
        colaborador.idGrupo,
        colaborador.Grupo,
        colaborador.idPuesto,
        colaborador.Puesto,
        colaborador.idPerfil,
        colaborador.Perfil,
        colaborador.EsUsuario,
        colaborador.Imagen,
        colaborador.UltimoAcceso
      ));
   
      return colaboradores;
    } catch (error) {
      console.error('Error fetching colaboradores:', error);
      throw error;
    }
  },

  async getColaborador(id) {
    try {
      const response = await apiClient.get(`/colaboradores/${id}`);
      const colaborador = response.data;
      return new Colaborador(
        colaborador.idEmpresa,
        colaborador.idColaborador,
        colaborador.colaborador,
        colaborador.telefono,
        colaborador.email,
        colaborador.fechaNacimiento,
        colaborador.idGrupo,
        colaborador.idPuesto,
        colaborador.idPerfil,
        colaborador.esUsuario,
        colaborador.ultimoAcceso
      );
    } catch (error) {
      console.error(`Error fetching colaborador with id ${id}:`, error);
      throw error;
    }
  },

  async createColaborador(colaborador) {
    try {
      const url = `/Colaboradores/nuevoColaborador?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}`;
      const response = await apiClient.post(url, colaborador);
      return response;
    } catch (error) {
      console.error('Error creating colaborador:', error);
      throw error;
    }
  },

  async updateColaborador(id, colaborador) {  
    try {
      const url = `/Colaboradores/actualizaColaborador?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}`;
     
      const response = await apiClient.put(url, colaborador);
      return response;
    } catch (error) {
      console.error(`Error al actualizar colaborador con el id ${id} mensaje:`, error);
      throw error;
    }
  },

  async deleteColaborador(id) {
    try {
      const url = `/Colaboradores/eliminaColaborador?idEmpresa=${localStorage.getItem('token')}&idUsuario=${localStorage.getItem('idUsuario')}&Cadena=${cadenaEncoded}&idColaborador=${id}`;
      console.log(url)
      const response = await apiClient.delete(url);
      return response;
    } catch (error) {
      console.error(`Error al eliminar colaborador con el id ${id} mensaje:`, error);
      throw error;
    }
  },

  formatDate(dateString) {
    // Verificar que dateString no esté vacío o sea null
    if (!dateString) {
      return null;
    }

    // Split de la fecha en partes usando "/"
    const parts = dateString.split('/');
    // Verificar que haya tres partes (DD, MM, YYYY)
    if (parts.length === 3) {
      const day = parseInt(parts[0]);
      const month = parseInt(parts[1]) - 1; // Los meses en JavaScript van de 0 a 11
      const year = parseInt(parts[2]);
      // Verificar que las partes sean números válidos
      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        // Crear un objeto Date con las partes
        const date = new Date(year, month, day);
        // Formatear la fecha en el formato "YYYY-MM-DD"
        const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
        return formattedDate;
      }
    }
    // Retorna null si no se puede parsear la fecha correctamente
    return null;
  }
};


