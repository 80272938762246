export default class TipoCliente {
    constructor(
      id,
      Elemento,
     
      
    ) {
    
      this.id = id;
      this.Elemento = Elemento;
      
     
    }
  }
  