<template>
  <v-container fluid>
    <v-card elevation="12" class="full-height-card">
      <v-data-table :headers="headers" :items="colaboradores" class="full-width-table">
        <template v-slot:[`item.Imagen64`]="{ item }">
          <v-avatar size="48px">
            <v-img v-if="item.Imagen64" alt="Avatar" :src="item.Imagen64"></v-img>
            <v-icon v-else icon="mdi-account-circle"></v-icon>
          </v-avatar>
        </template>

        <template v-slot:top>
          <v-toolbar flat :color="colors.secondary">
            <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ props }">
                <v-btn class="mb-2 mr-5" dark variant="outlined" v-bind="props">
                  Nuevo colaborador
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Nombre" :rules="[rules.required]"
                          @input="toUpperCase($event, 'Nombre')" label="Nombre"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Telefono" @input="handlePhoneNumberInput"
                          :rules="[rules.required]" label="Telefono"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.Email" :rules="[rules.required, rules.email]"
                          @input="toLowerCase($event, 'Email')" label="Email" @blur="validateEmail"
                          hint="www.example.com"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.FechaNacimiento" :rules="[rules.required]"
                          label="Fecha de nacimiento" type="date"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="select-with-button">
                          <v-select label="Grupos" :rules="[rules.required]" v-model="editedItem.idGrupo"
                            variant="outlined" :items="grupos" item-title="elemento" item-value="id"></v-select>
                          
                          <v-btn :color="colors.secondary" icon @click="abrirModalNuevoPuestoGrupo(1)"
                            class="select-button-Grupos">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="select-with-button">
                          <v-select label="Puestos" :rules="[rules.required]" v-model="editedItem.idPuesto"
                            variant="outlined" :items="puestos" item-title="elemento" item-value="id" hide-details
                            dense></v-select>
                          <v-btn :color="colors.secondary" icon @click="abrirModalNuevoPuestoGrupo(2)"
                            class="select-button">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex justify-center">
                        <v-avatar size="200px" class="mt-3">
                          <v-img v-if="editedItem.Imagen64" :src="editedItem.Imagen64"></v-img>
                          <v-icon v-else size="200">mdi-account-circle</v-icon>
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div @drop.prevent="handleDrop" @dragover.prevent="handleDragOver"
                          @dragleave.prevent="handleDragLeave" :class="['dropzone', { dragging: isDragging }]">
                          <div class="dropzone-content">
                            <v-icon size="48">mdi-camera</v-icon>
                            <p>Arrastra y suelta una imagen aquí, o haz clic para seleccionar una</p>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-switch v-model="editedItem.EsUsuario" color="red" label="Usuario" hide-details></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn :color="colors.secondary" @click="close">Cancel</v-btn>
                  <v-btn :color="colors.secondary" @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">¿Estás seguro de eliminar este registro?</v-card-title>
                <v-card-actions class="justify-center">
                  <v-btn color="error" variant="outlined" @click="closeDelete">Cancel</v-btn>
                  <v-btn :color="colors.secondary" variant="outlined" @click="deleteItemConfirm">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPuestoGrupo" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Agrega un nuevo {{ tituloNuevoPuestoGrupo }}</v-card-title>
                <v-text-field v-model="nombreGrupoPuesto" label="Nombre" :rules="[rules.required]"></v-text-field>
                <v-card-actions class="justify-center">
                  <v-btn color="error" variant="outlined" @click="dialogPuestoGrupo = false">Cancel</v-btn>
                  <v-btn :color="colors.secondary" variant="outlined" @click="CrearGrupoPuesto">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="me-2" size="small" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon size="small" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <AlertComponent />
  </v-container>
</template>

<script>
import { colors } from '../../CSS/Design/Color';
import ColaboradorService from '@/services/colaborador/ColaboradorService';
import GrupoService from '@/services/Grupo/GrupoService';
import PuestosService from '@/services/Puesto/PuestoService';
import { isValidEmail, filterNumbers } from '@/utils/utils';
import AlertComponent from '../../components/Alerts/AlertComponent.vue';
import { useAlertStore } from '../../stores/useAlertStore';

export default {
  components: {
    AlertComponent
  },
  data() {
    return {
      alertStore: useAlertStore(),
      rules: {
        required: v => !!v || 'Campo requerido',
        email: v => {
          const pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
          return pattern.test(v) || 'Email inválido';
        }
      },
      tituloNuevoPuestoGrupo: "",
      nombreGrupoPuesto: "",
      dialogPuestoGrupo: false,
      tituloModal: "Nuevo colaborador",
      isDragging: false,
      file: null,
      dialog: false,
      dialogDelete: false,
      colaboradores: [],
      grupos: [],
      puestos: [],
      editedIndex: -1,
      editedItem: {
        Nombre: '',
        Telefono: '',
        Email: '',
        FechaNacimiento: '',
        idGrupo: '',
        idPuesto: null,
        Perfil: '',
        EsUsuario: false,
        Imagen64: '',
      },
      defaultItem: {
        Nombre: '',
        Telefono: '',
        Email: '',
        FechaNacimiento: '',
        idGrupo: '',
        idPuesto: null,
        Perfil: '',
        EsUsuario: false,
        Imagen64: '',
      },
      headers: [
        { title: 'Foto', align: 'start', key: 'Imagen64' },
        { title: 'Nombre', align: 'start', key: 'Nombre' },
        { title: 'Email', key: 'Email' },
        { title: 'Puesto', key: 'Puesto' },
        { title: 'Perfil', key: 'Perfil' },
        { title: 'Grupo', key: 'Grupo' },
        { title: 'Telefono', key: 'Telefono' },
        { title: 'Actions', key: 'actions', sortable: false },
      ],
      titulo: 'Administración de usuarios',
      colors,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 100,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo colaborador' : 'Editar colaborador';
    },
  },
  methods: {
    handlePhoneNumberInput(event) {
      this.editedItem.Telefono = filterNumbers(event.target.value);
    },
    onSubmit() {
      if (!this.form) return

      this.loading = true

      setTimeout(() => (this.loading = false), 2000)
    },
    abrirModalNuevoPuestoGrupo(cual) {
      if (cual === 1) {//El numero 1 es el Grupo
        this.tituloNuevoPuestoGrupo = "Grupo";

      } else { //El numero 2 es el Puesto
        this.tituloNuevoPuestoGrupo = "Puesto";

      }
      this.dialogPuestoGrupo = true;
    },
    validateEmail() {
      if (!isValidEmail(this.editedItem.Email)) {
        // Aquí puedes manejar el caso de correo electrónico no válido,
        // por ejemplo, mostrar un mensaje de error o realizar alguna acción.
        console.log('Correo electrónico no válido');
      } else {
        console.log('Correo electrónico válido');
      }
    },
    toLowerCase(event, field) {
      this.editedItem[field] = event.target.value.toLowerCase();
    },
    toUpperCase(event, field) {
      this.editedItem[field] = event.target.value.toUpperCase();
    },

    handleFileInput(file) {
      if (file) {
        this.readFile(file);
      }
    },
    handleDrop(event) {
      this.isDragging = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.file = files[0];
        this.readFile(this.file);
      }
    },
    handleDragOver() {
      this.isDragging = true;
    },
    handleDragLeave() {
      this.isDragging = false;
    },
    readFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.editedItem.Imagen64 = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleFileInput_old() {
      const file = this.file;
      if (file && file instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          this.editedItem.Imagen64 = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        console.error('El archivo no es válido');
      }
    },
    async initialize() {
      try {
        const response = await ColaboradorService.getColaboradores();
        this.colaboradores = response;
      } catch (error) {
        console.error(error);
      }
    },
    async getGrupos() {
      try {
        const response = await GrupoService.getGrupos();
        this.grupos = response.map(item => ({
          elemento: item.Elemento,
          id: item.id,
        }));
        console.log(this.grupos)
      } catch (error) {
        console.error(error);
      }
    },
    async getPuestos() {
      try {
        const response = await PuestosService.getPuestos();
        this.puestos = response.map(item => ({
          elemento: item.Elemento,
          id: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    editItem(item) {
      this.editedIndex = this.colaboradores.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.colaboradores.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        const response = await ColaboradorService.deleteColaborador(this.editedItem.idColaborador);
        this.dialogDelete = false;
        console.log(response);
        if (response.data.Respuesta == "Ok") {
          this.initialize();
          this.alertStore.show('success', 'Colaborador eliminado.', '');
        } else {
          this.alertStore.show('error', 'No se pudo eliminar.', '');
        }
        // Actualiza la lista de colaboradores después de eliminar

      } catch (error) {
        console.error('Error eliminando colaborador:', error);
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
      this.file = null;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
    },
    validateEditedItem() {
      if (!this.editedItem.Nombre) return false;
      if (!this.editedItem.Telefono) return false;
      if (!this.editedItem.Email) return false;
      if (!this.editedItem.FechaNacimiento) return false;
      if (!this.editedItem.idGrupo) return false;
      if (!this.editedItem.idPuesto) return false;

      // Agrega más validaciones según los campos que necesites
      return true;
    },
    async save() {
      try {
        if (!this.validateEditedItem()) {
          this.alertStore.show('error', 'Todos los campos son obligatorios.', '');
          return;
        }
        if (this.editedIndex > -1) {
          console.log(this.editedItem)
          const response = await ColaboradorService.updateColaborador(this.editedItem.idColaborador, this.editedItem);

          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Colaborador actualizado.', '');
          } else {
            this.alertStore.show('error', 'No se pudo actualizar.', '');
          }
        } else {
          const response = await ColaboradorService.createColaborador(this.editedItem);

          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Colaborador agregado.', '');
          } else {
            this.alertStore.show('error', 'No se pudo crear.', '');
          }
        }
        this.initialize();
        this.close();
      } catch (error) {
        console.error(error);
      }
    },
    async CrearGrupoPuesto() {
      try {

        if (this.tituloNuevoPuestoGrupo == "Grupo") {
        
          const response = await GrupoService.createGrupo(this.nombreGrupoPuesto);
          console.log(response)
          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Grupo agregado.', '');
            this.getGrupos();
          } else {
            this.alertStore.show('error', 'No se pudo crear.', '');
          }

        } else {
          const response = await PuestosService.createPuesto(this.nombreGrupoPuesto)
          if (response.data.Respuesta == "Ok") {
            this.alertStore.show('success', 'Puesto agregado.', '');
            this.getPuestos();
          } else {
            this.alertStore.show('error', 'No se pudo crear.', '');
          }
          
        }
        this.dialogPuestoGrupo = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {

    await this.initialize();
    await this.getGrupos();
    await this.getPuestos();
  },

};
</script>


<style scoped>
.select-with-button {
  display: flex;
  align-items: center;
}

.select-button {
  margin-left: 2px;
  /* Ajusta esto según sea necesario */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 56px;
  /* Ajusta esto para que coincida con la altura del select */
  min-width: 56px;
  /* Ajusta esto para que coincida con la altura del select */
}

.select-button-Grupos {
  margin-left: 2px;
  /* Ajusta esto según sea necesario */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-bottom: 20px;
  height: 56px;

  /* Ajusta esto para que coincida con la altura del select */
  min-width: 56px;
  /* Ajusta esto para que coincida con la altura del select */
}

.full-height-card {
  height: 100%;
}

.full-width-table {
  width: 100%;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
}

.dropzone.dragging {
  border-color: #42a5f5;
  background-color: #e3f2fd;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {

  .v-toolbar-title,
  .v-btn,
  .v-icon,
  .v-avatar,
  .dropzone-content p {
    font-size: 12px;
  }

  .v-avatar {
    width: 100px;
    height: 100px;
  }

  .dropzone {
    padding: 10px;
  }
}
</style>
