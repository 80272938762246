import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login/LoginView.vue';
import UserDashboard from '@/views/MainDashboard.vue'; // Asegúrate de usar el nuevo nombre
import Colaboradores from '../views/colaboradores/ColaboradoresView.vue'
import Grupos from '../views/catalogos/CatalogoGrupos.vue'
import Puestos from '../views/catalogos/CatalogoPuestos.vue'
import Clientes from '../views/clientes/ClientesView.vue'
import ClientesTipo from '../views/catalogos/CatalogoTiposCliente.vue'
import ClientesGrupo from '../views/catalogos/CatalogoGruposCliente.vue'
import NotFound from '../views/NotFound.vue'
const routes = [
    {
        path: '/',
        redirect: '/login' // Redirigir a /login por defecto
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/Inicio',
        name: 'Dashboard',
        component: UserDashboard, // Usa el nuevo nombre del componente
        meta: { requiresAuth: true },
        children: [
          {
            path: '/Inicio/Colaboradores',
            name: 'Colaboradores',
            component: Colaboradores,
          },
          {
            path: '/Inicio/Grupos',
            name: 'Grupos',
            component: Grupos,
          },
          {
            path: '/Inicio/Puestos',
            name: 'Puestos',
            component: Puestos,
          },
          {
            path: '/Inicio/Clientes',
            name: 'Clientes',
            component: Clientes,
          },
          {
            path: '/Inicio/TipoClientes',
            name: 'ClientesTipo',
            component: ClientesTipo,
          },
          {
            path: '/Inicio/GrupoClientes',
            name: 'ClientesGrupo',
            component: ClientesGrupo,
          },
        ]
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound
    }
    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  
  router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('Cadena');
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
      next({ name: 'Login' }); // Redirigir al login si no está autenticado y trata de acceder a una ruta protegida
    } else {
      next();
    }
  });

export default router;
