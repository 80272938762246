import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router'; // Asegúrate de importar el router sin llaves
import { createPinia } from 'pinia'; // Importar Pinia

loadFonts();

const pinia = createPinia(); // Crear instancia de Pinia

const app = createApp(App);

app
  .use(vuetify)
  .use(router) // Asegúrate de usar el router aquí
  .use(pinia)  // Usar Pinia
  .mount('#app');